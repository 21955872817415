/* eslint-disable-next-line import/no-unresolved */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
/* eslint-disable-next-line import/no-unresolved */
import { useSelector, useDispatch } from 'react-redux';
import { useAccountInfo } from './useAccountInfo';
import { ALL_SERVICES, SYSTEM_NOTIFICATION_TYPES } from '../constants/core';
import { isEqual } from '../utils';
import { useReloadApp, useReloadedApp } from './useReloadingApp';
import { resetIsMaintenanceModalVisible } from '../redux/actions/settingsActions';

export const useSemiBlockingModalDisplayControl = (isTargetAll) => {
  const accountInfo = useAccountInfo();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const prevIsAuthenticated = useRef(isAuthenticated);
  const serviceId = useSelector((state) => state.auth.serviceId);
  const screen = useSelector((state) => state.screen.screen);
  const screenRef = useRef(screen);
  const [confirmedInitialScreen, setConfirmedInitialScreen] = useState(!!screen);
  const isTargetAllRef = useRef(isTargetAll);
  const timestampsRef = useRef({ maintenance: Date.now(), lossCut: Date.now() });
  const prevAccountInfoRef = useRef(null);
  const visibleMaintenanceModal = useSelector(
    (state) => state.system.systemNotificationModalVisible[SYSTEM_NOTIFICATION_TYPES.MAINTENANCE],
  );
  const [reloaded] = useReloadedApp();
  const reloadedRef = useRef(reloaded);
  const justLoginRef = useRef(false);
  const isMaintenanceModalVisible = useSelector((state) => state.settings.maintenanceInfo.isMaintenanceModalVisible);

  useEffect(() => {
    if (visibleMaintenanceModal) {
      // メンテモーダルが表示された時点でフラグは落とす
      justLoginRef.current = false;
    }
  }, [visibleMaintenanceModal]);

  useEffect(() => {
    if (reloaded) {
      // システム的にリロードされていた場合、フラグをセット
      reloadedRef.current = reloaded;
    }
  }, [reloaded]);

  // 初期画面の確定を監視
  useEffect(() => {
    if (screenRef.current !== screen && justLoginRef.current) {
      // ログイン直後の画面を移動した時点でフラグをオフにしておく
      justLoginRef.current = false;
    }
    if (!screenRef.current && screen) {
      // モバイルの場合、画面未確定のタイミングが存在するため
      setConfirmedInitialScreen(true);
    }
    screenRef.current = screen;
  }, [screen]);

  return useMemo(() => {
    if (prevIsAuthenticated.current !== isAuthenticated) {
      if (!prevIsAuthenticated.current && isAuthenticated) {
        // 未ログイン -> ログイン
        if (!reloadedRef.current && !visibleMaintenanceModal) {
          // システムによるリロードでなく、メンテモーダルが表示中でもない場合フラグを立てる
          justLoginRef.current = true;
        }
      }
      prevIsAuthenticated.current = isAuthenticated;
    }
    const prevAccountInfo = prevAccountInfoRef.current;
    if (prevAccountInfo !== accountInfo) {
      if (prevAccountInfo != null) {
        const prevExistServices = ALL_SERVICES.filter((service) => !prevAccountInfo[service].notExist);
        const existServices = ALL_SERVICES.filter((service) => !accountInfo[service].notExist);
        if (isEqual(prevExistServices, existServices)) {
          const timestamps = timestampsRef.current;
          existServices.forEach((service) => {
            if (!prevAccountInfo[service].isMaintenance && accountInfo[service].isMaintenance) {
              timestamps.maintenance = Date.now();
            }
            if (!prevAccountInfo[service].isLossCut && accountInfo[service].isLossCut) {
              timestamps.lossCut = Date.now();
            }
          });
        }
      }
      prevAccountInfoRef.current = accountInfo;
    }
    const isAllServiceMaintenance = ALL_SERVICES.every((service) => accountInfo[service].isMaintenance);
    const existServices = ALL_SERVICES.filter((service) => !accountInfo[service].notExist);
    const isAllMaintenance = existServices.every((service) => accountInfo[service].isMaintenance);
    const isAllLossCut = existServices.every((service) => accountInfo[service].isLossCut);
    const isAllNotAvailable = existServices.every((service) => accountInfo[service].isNotAvailable);
    const isCurrentMaintenance = accountInfo[serviceId].isMaintenance;
    const isCurrentLossCut = accountInfo[serviceId].isLossCut;
    const visibleBlockingMaintenance =
      (isAllServiceMaintenance || (isAllMaintenance && isAuthenticated)) && isMaintenanceModalVisible;
    const visibleBlockingLossCut =
      !visibleBlockingMaintenance && isAuthenticated && (isAllLossCut || isAllNotAvailable);
    const isBlocked = visibleBlockingMaintenance || visibleBlockingLossCut;
    const targetAll = isTargetAllRef.current?.(screenRef.current) ?? false;
    // 刷新前からの仕様で、ログイン直後は選択されたサービス以外でもメンテナンスに関しては通知する
    const visibleMaintenance =
      (targetAll || justLoginRef.current
        ? existServices.some((service) => accountInfo[service].isMaintenance)
        : isCurrentMaintenance) && isMaintenanceModalVisible;
    if (!visibleMaintenance && justLoginRef.current) {
      // このタイミングでメンテ中でない場合はフラグを落とす
      justLoginRef.current = false;
    }
    const visibleLossCut = targetAll
      ? existServices.some((service) => accountInfo[service].isLossCut)
      : isCurrentLossCut;
    const isBlockedMaintenance = isBlocked || !visibleMaintenance;
    const isBlockedLossCut = isBlocked || !visibleLossCut || visibleMaintenanceModal;
    const isOpenSemiBlockingModal =
      visibleBlockingMaintenance || visibleBlockingLossCut || visibleMaintenance || visibleLossCut;
    return {
      visibleBlockingMaintenance,
      visibleBlockingLossCut,
      visibleMaintenance,
      visibleLossCut,
      isBlockedMaintenance,
      isBlockedLossCut,
      isOpenSemiBlockingModal,
      changeMaintenanceTimestamp: timestampsRef.current.maintenance,
      changeLossCutTimestamp: timestampsRef.current.lossCut,
    };
    // 下記はモバイルにおいて画面未確定のタイミングが存在するための措置で、confirmedInitialScreen の変更をトリガーに再評価させる
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountInfo, isAuthenticated, serviceId, visibleMaintenanceModal, confirmedInitialScreen]);
};

export const useSemiBlockingModal = ({ visible, isBlock, isBlocked, timestamp, ready, disabled }) => {
  const reloadApp = useReloadApp();
  const reloadRef = useRef(null);
  const [reloaded, setReloaded] = useReloadedApp();
  const reloadedRef = useRef(null);
  const serviceId = useSelector((state) => state.auth.serviceId);
  const accountInfo = useAccountInfo();
  const [innerVisible, setInnerVisible] = useState(visible);
  const [isOpen, setOpen] = useState(false);
  const disabledRef = useRef(ready ? disabled : false);

  const isNotAvailable = useMemo(() => {
    return accountInfo[serviceId].isNotAvailable;
  }, [serviceId, accountInfo]);

  const open = useCallback(() => {
    if (disabledRef.current) {
      setInnerVisible(false);
    } else {
      setOpen(true);
    }
  }, []);

  const dispatch = useDispatch();

  const close = useCallback(() => {
    setOpen(false);
    setInnerVisible(false);
  }, []);

  const reload = useCallback(() => {
    if (!disabledRef.current) {
      reloadRef.current?.();
    }
    reloadRef.current = null;
  }, []);

  const onClose = useCallback(() => {
    dispatch(resetIsMaintenanceModalVisible());
    close();
    reload();
  }, [close, reload, dispatch]);

  useEffect(() => {
    if (!ready) {
      return;
    }
    const f = () => {
      if (isBlock) {
        // blocking modal は単純に visible に連動
        setInnerVisible(visible);
      } else if (visible) {
        // semi blocking modal は手動でしか閉じられない為、timestamp が変わった時点で再評価
        setInnerVisible(true);
      } else {
        if (reloadedRef.current) {
          reloadedRef.current = false;
        }
        setInnerVisible(false);
      }
    };

    if (disabledRef.current !== disabled) {
      if (!disabled) {
        // disabled から enabled になった直後は、最上位ですでに表示済みということなので表示対象としない
        setInnerVisible(false);
      } else {
        f();
      }
      disabledRef.current = disabled;
    } else {
      f();
    }
  }, [isBlock, visible, ready, disabled, timestamp]); // timestamp は状態更新検知のみで利用

  useEffect(() => {
    if (reloaded) {
      // リロード直後であることを確認したらグローバルのフラグはOFF
      setReloaded(false);
      if (!isBlock) {
        // semi blocking modal の場合のみフラグ制御
        reloadedRef.current = true;
      }
    }
  }, [isBlock, reloaded, setReloaded]);

  useEffect(() => {
    if (isOpen) {
      // 開いている最中に現在サービスが無効になった場合は閉じる際にリロードさせる
      if (isNotAvailable) {
        reloadRef.current = reloadApp;
      }
    }
  }, [isOpen, isNotAvailable, reloadApp]);

  useEffect(() => {
    if (isBlocked) {
      // 他の blocking modal が表示されているということなので、以降はリロード判定不要
      if (reloadedRef.current) {
        reloadedRef.current = false;
      }
      // 割り込みの場合はリロード不要
      reloadRef.current = null;
      close();
      return;
    }
    if (isBlock) {
      // blocking modal は単純に innerVisible に連動
      if (innerVisible) {
        open();
      } else {
        close();
        reload();
      }
      return;
    }
    if (innerVisible) {
      // semi blocking modal は閉じるボタン押下によってのみ閉じられる
      if (reloadedRef.current) {
        // リロード復帰直後の1回目は自明の為モーダルを表示させない
        reloadedRef.current = false;
        close();
      } else {
        open();
      }
    }
  }, [innerVisible, isBlock, isBlocked, reload, close, open, setReloaded]);

  return useMemo(() => ({ isOpen, onClose }), [isOpen, onClose]);
};
